import { gql } from '@apollo/client';
import client from '@xFrame4/business/GraphQlClient';
import BusinessEntity, { EntityManager } from '@xFrame4/business/base/BusinessEntity';
import ManyToManyCollection from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityFieldType } from '../base/Constants';
import Group from './Group';
import Permission from './Permission';

/**
 * Represents the Django/PHP User class.
 */
export class User extends BusinessEntity
{
    pk!: number;
    username!: string;
    email!: string;
    password?: string;
    verified!: boolean
    isStaff: boolean = false;
    isActive: boolean = true;
    dateJoined!: string;
    firstName: string = '';
    lastName: string = '';
    nickname: string = '';
    name: string = '';
    facebookLoginId: string = '';
    googleLoginId: string = '';
    static createEntity = () => new User();
    groups: ManyToManyCollection<Group> = new ManyToManyCollection<Group>(this, 'xFrame/business/users/User', 'groups', 'xFrame/business/users/Group', Group.manager);
    permissions: ManyToManyCollection<Permission> = new ManyToManyCollection<Permission>(this, 'xFrame/business/users/User', 'permissions', 'xFrame/business/users/Permission', Permission.manager);

    static manager: EntityManager<User> = new EntityManager<User>({
        name: 'UserNode',
        createEntity: this.createEntity,
        fields: [
            { name: 'pk', type: BusinessEntityFieldType.Integer, isInput: false },
            { name: 'username', type: BusinessEntityFieldType.VarChar, isInput: false },
            { name: 'email', type: BusinessEntityFieldType.VarChar },
            { name: 'password', type: BusinessEntityFieldType.VarChar, isInFieldDetails: false },
            { name: 'verified', type: BusinessEntityFieldType.Boolean, isInput: false },
            { name: 'isStaff', type: BusinessEntityFieldType.Boolean, isInput: false },
            { name: 'isActive', type: BusinessEntityFieldType.Boolean },
            { name: 'dateJoined', type: BusinessEntityFieldType.DateTime, isInput: false },
            { name: 'firstName', type: BusinessEntityFieldType.VarChar },
            { name: 'lastName', type: BusinessEntityFieldType.VarChar },
            { name: 'nickname', type: BusinessEntityFieldType.VarChar },
            { name: 'name', type: BusinessEntityFieldType.VarChar },
            { name: 'facebookLoginId', type: BusinessEntityFieldType.VarChar, isInput: false },
            { name: 'googleLoginId', type: BusinessEntityFieldType.VarChar, isInput: false },
        ],
        graphQlQueryAlias: 'users',
        graphQlSaveAlias: 'saveUser',
        graphQlInputAlias: 'UserInput',
        graphQlDeleteAlias: 'deleteUser',
        hasTotalCount: false,
        hasPages: false,
    });

    /** The GraphQL fragment for use in the custom queries and mutations. */
    static UserDetailsGraphQlFragment = `
        fragment UserDetailsFragment on UserNode {
            id
            pk
            username
            email
            verified
            isStaff
            isActive
            dateJoined
            firstName
            lastName
            nickname
            name
            facebookLoginId
            googleLoginId
        }
    `;

    /**
     * The user's full name.
     */
    get fullName()
    {
        if (this.name != '')
        {
            return this.name;
        }
        else
        {
            return this.firstName + ' ' + this.lastName;
        }
    }

    // Override
    copyFromGraphQL(graphQlObject: any)
    {
        super.copyFromGraphQL(graphQlObject);

        this.id = this.pk;
    }

    /**
     * Deactivate the user. Use this instead of deleting the account.
     */
    async deActivate()
    {
        this.isActive = false;
        return await this.save();
    }

    /**
     * Check if the user has the given permission.
     * 
     * @param permissionCodenames 
     * @returns True if the user has all the permissions.
     */
    async hasPermissions(permissionCodenames: string[])
    {
        let query = `
        query UserHasPermissions($id: Int!, $permissionCodenames: [String]!) {
            userHasPermissions(id: $id, permissionCodenames: $permissionCodenames)
        }
        `;
        
        let { data } = await client.query({
            query: gql(query),
            variables: {
                id: this.id,
                permissionCodenames: permissionCodenames
            }
        });

        return data.userHasPermissions as boolean;
    }

    /**
     * Set the permissions for the user.
     * 
     * @param permissionCodenames The codenames of the permissions to set.
     */
    async setPermissions(permissionCodenames: string[])
    {
        let query = `
        mutation SetPermissionsForUser($id: Int!, $permissionCodenames: [String]) {
            setPermissionsForUser(id: $id, permissionCodenames: $permissionCodenames) {
                success
            }
        }
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                id: this.id,
                permissionCodenames: permissionCodenames
            }
        });

        return data.setPermissionsForUser.success as boolean;
    }

    /**
     * Check the password for an user email.
     * 
     * @param email User email
     * @param password User password
     */
    static async checkPassword(email: string, password: string)
    {
        let query = `
        query CheckPassword($email: String!, $password: String!) {
            checkPassword(email: $email, password: $password)
        }
        `;

        let { data } = await client.query({
            query: gql(query),
            variables: {
                email: email,
                password: password
            }
        });

        return data.checkPassword as boolean;
    }

    /**
     * Change the password for the user.
     * For this to work, the user must be logged in, otherwise it will return false.
     */
    static async changePassword(currentPassword: string, newPassword: string)
    {
        let query = `
            mutation ChangePassword($currentPassword: String!, $newPassword: String!)
            {
                changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
                    success
                }
            }
        `;

        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                currentPassword: currentPassword,
                newPassword: newPassword
            }
        });

        return data.changePassword.success as boolean;
    }
}

export default User;