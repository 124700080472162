import fetch from 'cross-fetch';
import { ApolloClient, DefaultOptions, HttpLink, InMemoryCache, gql } from '@apollo/client';
import config from '@config';

export class TokenManager
{
    static localStorageTokenIdentifier = 'token';
    static localStorageRefreshTokenIdentifier = 'refreshToken';

    /** Create an Apollo client for JWT purposes. */
    createClient()
    {
        // Disable cache.
        const defaultOptions: DefaultOptions = {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        }

        // Create client.
        return new ApolloClient({
            link: new HttpLink({ uri: config.apiUrl, fetch }),
            cache: new InMemoryCache(),
            defaultOptions: defaultOptions
        });
    }

    /**
     * Try to get the JWT from the local storage. 
     * If the token is expired it will try to get a new one with the refresh token.
     * 
     * @returns The token or nothing.
     */
    async getTokenFromLocalStorage(): Promise<string | null>
    {
        let token = null;

        // Try to get token from the local storage
        token = localStorage.getItem(TokenManager.localStorageTokenIdentifier) as string;
        if (token != null)
        {
            // Ask for a new token if it's expired
            let jwt = this.parseJwt(token);
            if (this.isJwtExpired(jwt))
            {
                token = await this.refreshToken();
            }
        }

        return token;
    }

    /**
    * Refresh the token.
    * 
    * @returns The new token. Undefined if unsuccessful.
    */
    async refreshToken()
    {
        let token = null;

        // Read token from local storage
        let refreshToken = localStorage.getItem(TokenManager.localStorageRefreshTokenIdentifier);
        if (refreshToken != null)
        {
            // Try to refresh the token.
            let client = this.createClient();

            let query = `
            mutation RefreshUserToken($refreshToken: String!) {
                refreshToken(refreshToken: $refreshToken) {
                    success
                    token
                    refreshToken
                }
            }
            `;

            let { data } = await client.mutate({
                mutation: gql(query),
                variables: {
                    refreshToken: refreshToken
                }
            });

            if (data?.refreshToken?.success)
            {
                token = data.refreshToken.token as string;

                // Save new tokens to the local storage.
                this.saveTokensToLocalStorage(data.refreshToken.token as string, data.refreshToken.refreshToken as string);
            }
        }

        return token;
    }

    /**
     * Check if the JWT is expired.
     * 
     * @param jwt Decoded JWT.
     */
    isJwtExpired(jwt: any)
    {
        return Date.now() >= jwt.exp * 1000;
    }

    /**
     * Decode a JWT.
     * 
     * @param token JWT as a string.
     * @returns JWT as JSON
     */
    parseJwt(token: string)
    {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c)
        {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    /**
     * Save JWT and the refresh token to the local storage.
     * 
     * @param token 
     * @param refreshToken 
     */
    saveTokensToLocalStorage(token: string, refreshToken: string)
    {
        localStorage.setItem(TokenManager.localStorageTokenIdentifier, token);
        localStorage.setItem(TokenManager.localStorageRefreshTokenIdentifier, refreshToken);
    }
}

export default TokenManager;