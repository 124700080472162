import fetch from 'cross-fetch';
import { ApolloCache, ApolloClient, ApolloClientOptions, ApolloQueryResult, DefaultContext, DefaultOptions, FetchResult, HttpLink, HttpOptions, InMemoryCache, MutationOptions, OperationVariables, QueryOptions } from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import config from '@config';
import TokenManager from '@xFrame4/business/TokenManager';

/**
 * A wrapper around ApolloClient that adds authorization headers to the context.
 */
export class GraphQlClient<TCacheShape> extends ApolloClient<TCacheShape>
{
    constructor(options: ApolloClientOptions<TCacheShape>)
    {
        super(options);
    }

    /**
     * Make a query.
     * 
     * @param options 
     */
    async query<T = any, TVariables = OperationVariables>(
        options: QueryOptions<OperationVariables, T>
    ): Promise<ApolloQueryResult<T>>
    {
        // Try to get current user token
        let tokenManager = new TokenManager(); 
        let token: string | null = null;
        if (typeof localStorage != 'undefined')
        {
            token = await tokenManager.getTokenFromLocalStorage() as string;
        }

        // Add Authorization headers to the context
        if (token != null)
        {
            options.context = {
                headers:
                {
                    Authorization: 'JWT ' + token
                }
            };
        }

        // Make the query
        try
        {
            let result = await super.query(options);
            return result;
        }
        catch (error)
        {
            let handledError = this.handleGraphQlError(error);
            throw handledError;
        }
    }

    /**
     * Make a mutation.
     * 
     * @param options 
     */
    async mutate<TData = any, TVariables extends OperationVariables = OperationVariables, TContext extends DefaultContext = DefaultContext>(
        options: MutationOptions<TData, TVariables, TContext>
    ): Promise<FetchResult<TData, Record<string, any>, Record<string, any>>>
    {
        // Try to get current user token
        let tokenManager = new TokenManager();  
        let token: string | null = null;
        if (typeof localStorage != 'undefined')
        {
            token = await tokenManager.getTokenFromLocalStorage() as string;
        }

        // Add Authorization headers to the context
        if (token != undefined)
        {
            /* @ts-ignore */
            options.context = {
                headers:
                {
                    Authorization: 'JWT ' + token
                }
            };
        }

        // Make the mutation
        try
        {
            let result = await super.mutate(options);
            return result;
        }
        catch (error)
        {
            let handledError = this.handleGraphQlError(error);
            throw handledError;
        }
    }

    /**
     * Handle the error returned from a GraphQL query or mutation.
     */
    handleGraphQlError(error: any)
    {
        // Check if the error is a network error:
        // Reason 1: the JSON is not valid (probably because a warning/error was returned from the server)
        // Reason 2: the JSON is valid, but the server returned an error
        let networkError = (error as any).networkError;
        if (networkError != null)
        {
            console.log(networkError);
            let message = networkError.message;
            if (networkError.bodyText !== undefined)
            {
                message += '\n\n' + networkError.bodyText;
            }
            else if (networkError.result !== undefined && networkError.result.errors !== undefined)
            {
                if (networkError.result.errors.length > 0 && networkError.result.errors[0].message !== undefined)
                {
                    message += '\n\n' + networkError.result.errors[0].message;
                }
            }
            
            return new Error('Network error: ' + message);
        }
        else
        {
            return error;
        }
    }
}

// Disable cache.
const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}

// Create client.
export const client = new GraphQlClient({
    //link: new HttpLink({ uri: config.apiUrl, fetch }),
    link: createUploadLink({ uri: config.apiUrl, fetch }),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
});

export default client;