import { gql } from '@apollo/client';
import client from '@xFrame4/business/GraphQlClient';
import BusinessEntity, { EntityManager } from '@xFrame4/business/base/BusinessEntity';
import ManyToManyCollection from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityFieldType } from '../base/Constants';
import User from './User';
import Permission from './Permission';

export class Group extends BusinessEntity
{
    name!: string;
    users: ManyToManyCollection<User> = new ManyToManyCollection<User>(this, 'xFrame/business/users/Group', 'users', 'xFrame/business/users/User', User.manager);
    permissions: ManyToManyCollection<Permission> = new ManyToManyCollection<Permission>(this, 'xFrame/business/users/Group', 'permissions', 'xFrame/business/users/Permission', Permission.manager);

    static manager: EntityManager<Group> = new EntityManager<Group>({
        name: 'Group',
        createEntity: () => new Group(),
        fields: [
            { name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
        ],
        graphQlQueryAlias: 'groups'
    });

    /**
     * Check if the group has the given permission.
     * 
     * @param permissionCodenames The codenames of the permissions to check.
     * @returns True if the group has all the permissions.
     */
    async hasPermissions(permissionCodenames: string[])
    {
        let query = `
        query GroupHasPermissions ($id: Int!, $permissionCodenames: [String]!) {
            groupHasPermissions (id: $id, permissionCodenames: $permissionCodenames)
        }
        `;
        
        let { data } = await client.query({
            query: gql(query),
            variables: {
                id: this.id,
                permissionCodenames: permissionCodenames
            }
        });

        return data.groupHasPermissions as boolean;
    }

    /**
     * Set the permissions for the group.
     * 
     * @param permissionCodenames The codenames of the permissions to set.
     */
    async setPermissions(permissionCodenames: string[])
    {
        let query = `
        mutation SetPermissionsForGroup($id: Int!, $permissionCodenames: [String]) {
            setPermissionsForGroup(id: $id, permissionCodenames: $permissionCodenames) {
                success
            }
        }
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                id: this.id,
                permissionCodenames: permissionCodenames
            }
        });

        return data.setPermissionsForGroup.success as boolean;
    }
}

export default Group;