/**
 * Base class for classes that need event listening (Observer pattern).
 */
export default class xObject
{
	eventHandlers: xEventHandler[] = [];

	constructor()
	{

	}

	/**
	 * Subscribe to an event with a function. Eg: this.on('click', () => {});
	 * 
	 * @param eventName 
	 * @param fn 
	 */
	on(eventName: string, fn: Function)
	{
		let eventHandler: xEventHandler = {
			eventName: eventName, 
			fn: fn
		};
		this.eventHandlers.push(eventHandler);
	}

	/**
	 * Fire an event. Executes all the functions subscribed to it, in the order they were subscribed.
	 * 
	 * @param eventName 
	 * @param  args 
	 */
	protected fire(eventName: string, ...args: any) 
	{
		console.log('Fire: ' + eventName);
		
		for (let i = 0; i < this.eventHandlers.length; i++) 
		{
			let eventHandler = this.eventHandlers[i];
			if (eventHandler.eventName == eventName) 
			{
				eventHandler.fn(...args);
			}
		}
	}

	/**
	 * Check if event handlers exist for the event.
	 * 
	 * @param eventName 
	 */
	protected eventHandlerExists(eventName: string)
	{
		for (let i = 0; i < this.eventHandlers.length; i++) 
		{
			let eventHandler = this.eventHandlers[i];
			if (eventHandler.eventName == eventName) 
			{
				return true;
			}
		}

		return false;
	}
}

interface xEventHandler
{
	eventName: string;
	fn: Function;
}