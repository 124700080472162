export enum BusinessEntityFieldType
{
    PrimaryKey = "pk",
    /** A BusinessEntity field that has a foreign key relationship with this entity. */
    ForeignKey = "fk",
    /** A list for related BusinessEntity objects that have foreign key relationships with this entity. Something like 'related_name' in Django. */
    ReverseForeignKey = "reverse_fk",
    /** A list of unrelated BusinessEntity objects. */
    BusinessEntityArray = "business_entity_array",
    VarChar = "varchar",
    Text = "text",
    Integer = "int",
    Decimal = "decimal",
    Boolean = "tinyint",
    Date = "date",
    Time = "time",
    DateTime = "datetime",
    TimeStamp = "timestamp",
    Json = "json",
    File = "file",
    FileList = "file_list",
    /** A custom object that. CustomGraphQl has to be defined for this to work. */
    Custom = "custom",
}